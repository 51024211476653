.how_to {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0c0f06;
  flex-direction: column;

  h1 {
    font-family: $saira-700;
    font-size: 24px;
    margin: 0;
  }

  &_close {
    padding: 8px;
    background: #D72727;
    color: #FFFFFF;
    font-family: $saira-700;
    font-size: 20px;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    right: 40px;
    top: 20px;
    line-height: 1;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
.slider-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px 40px;
  overflow: hidden;
  box-sizing: border-box;



  .slider-content {
    text-align: center;
    padding: 0 16px 16px;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    height: 430px;
    box-sizing: border-box;
    img {
      display: block;
      width: 100%;
      height: auto;
    }

    &.fade-right {
      animation: slideLeft 0.3s ease-in-out;
    }

    &.fade-left {
      animation: slideRight 0.3s ease-in-out;
    }

    p {
      font-size: 12px;
      line-height: 18px;
      font-family: $saira-400;
    }
  }

  .slider-controls {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    background: #FFFFFF;
    left: 0;


    .buttons_wrapper {
      position: relative;
      width: 100%;
    }


    button {
      position: absolute;
      background: none;
      border: none;
      cursor: pointer;
      padding: 10px;
      transition: opacity 0.3s ease;
      &:nth-child(1){
        left: 0;
      }
      &:nth-child(2){
        right: 0;
      }

      svg {
        width: 20px;
        height: auto;
        display: block;
      }

      &.disabled {
        opacity: 0.2;
        cursor: not-allowed;
      }

      //&:hover:not(.disabled) {
      //  opacity: 0.7;
      //}
    }
  }
}

@keyframes slideLeft {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideRight {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

