.nav {
  padding: 16px;
  list-style: none;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #070f04;
  z-index: 4;
  max-width: 430px;
  margin: 0 auto;
  .app_v {
    color: $white_50;
    position: absolute;
    bottom: 2px;
    right: 16px;
    font-size: 9px;
  }

  &_wrapper {
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 4px;
    list-style: none;
    background: #1B4A0C;
    position: relative;
  }

  .aidrop {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    z-index: 20;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: #1b4b0c;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border: 1px solid #3d762b;
    -webkit-box-shadow: 0px 1px 20px -6px rgba(60, 239, 163, 1);
    -moz-box-shadow: 0px 1px 20px -6px rgba(60, 239, 163, 1);
    box-shadow: 0px 1px 20px -6px rgba(60, 239, 163, 1);
    text-decoration: none;

    span {
      color: #fff;
      font-family: $saira-600;
      font-size: 14px;
    }
  }

  &_item {

    box-sizing: border-box;

    &:nth-child(2) {
      margin-right: auto;

      a {
        justify-content: flex-start;
      }
    }

    &:nth-child(3) {
      a {
        justify-content: flex-end;
      }
    }

    a {
      pointer-events: unset;
      border-radius: 12px;
      padding: 12px;
      color: $white_50;
      text-align: center;
      font-size: 14px;
      line-height: 1;
      text-decoration: none;
      display: flex;
      font-family: $saira-400;
      justify-content: center;
      align-items: center;

      &.active {
        color: #fff;
        position: relative;
        background: #316620;
      }
    }


  }
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
}
