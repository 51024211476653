.friends_page {
  .frame_content {
    padding-top: 22px;
  }


  &_banner {
    width: 90px;
    height: auto;
    margin-bottom: 20px;
  }

  &_title {
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    font-family: $saira-600;
    margin: 0 0 20px;
  }

  &_description {
    font-size: 16px;
    text-align: center;
    color: $white_50;
    margin: 0 0 16px;
    line-height: 18px;
    font-family: $saira-300;
  }

  .stat_data {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 16px;
    border: 1px solid rgba(64, 64, 64, 0.6);
    background: rgba(255, 255, 255, 0.04);
    backdrop-filter: blur(2px);
    padding: 16px;
    box-sizing: border-box;
    margin-bottom: 10px;

    .stat_total, .stat_earn {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;

      &_title {
        font-size: 12px;
        font-family: $saira-300;
        line-height: normal;
        color: $white-50;
        margin: 0 0 4px;
      }

      &_value {
        display: flex;
        align-items: center;
        font-family: $saira-600;
        font-size: 20px;
        line-height: 20px;
      }
    }

    .stat_total {
      border-right: 1px solid rgba(64, 64, 64, 0.6);
    }

    .stat_earn {
    }
  }

  .stat_detail {
    text-decoration: underline;
    color: $white_50;
    margin: 0;
    font-size: 14px;
    font-family: $saira-400;
  }

  .invite_button {
    position: fixed;
    bottom: 80px;
    left: 0;
    padding: 0 16px 0;
    right: 0;

    &_button {
      width: 100%;
      background: #4d9636;
      margin: 0;
      padding: 15px 45px;
      font-size: 16px;
      text-align: center;
      transition: 0.3s ease-in-out;
      background-size: 200% auto;
      border: none;
      color: white;
      border-radius: 10px;
      background-position: right center;
      display: block;
      font-family: $saira-600;
      line-height: 1;

      &.no_bg {
        border: 1px solid rgba(64, 64, 64, 0.6);
        background: rgba(255, 255, 255, 0.04);
        backdrop-filter: blur(2px);
      }

      &:hover {
        background: #346c23;
        color: #fff;
        text-decoration: none;
      }

      @media (min-width: 768px) {
        font-size: 24px;
        min-width: 196px;
      }
    }
  }

  .friends_list_wrapper {
    position: fixed;
    top: calc(100vh + 3px);
    z-index: 3;
    left: 0;
    right: 0;
    height: calc(100vh - 78px);
    transition: top 0.3s ease-in-out;
    @media screen and (orientation: landscape) {
      display: none;
    }

    &.open {
      transition: top 0.3s ease-in-out;
      top: 72px
    }

    .friends_list {
      background: #070f04;
      height: 100%;
      position: relative;
      border-top-left-radius: 32px;
      border-top-right-radius: 32px;
      padding: 32px 16px 0;
      max-width: 430px;
      margin: 0 auto;
      box-sizing: border-box;

      &_invite_links {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h2 {
          font-size: 24px;
          line-height: 28px;
          text-align: center;
          font-family: $saira-600;
          margin: 0 0 20px;
        }

        p {
          font-size: 16px;
          text-align: center;
          color: $white_80;
          margin: 0 0 16px;
          line-height: 18px;
          font-family: $saira-300;
        }

        .buttons_wrapper {
          margin: 0 -8px;
          display: flex;

          .share_cta {
            text-decoration: none;
            width: 50%;
            box-sizing: border-box;
            padding: 0 8px;
          }

          .copy_cta {
            width: 50%;
            box-sizing: border-box;
            padding: 0 8px;
          }
        }
      }

      &_empty {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
          font-size: 16px;
          text-align: center;
          color: $white_80;
          margin: 0 auto 26px;
          line-height: 18px;
          font-family: $saira-300;
          max-width: 300px;
        }
      }

      &_title {
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        font-family: $saira-600;
        margin: 0 0 20px;
      }

      &_list {
        padding-top: 10px;
        overflow: scroll;
        position: relative;
        height: calc(100% - 132px);

        .item {
          display: flex;
          border-bottom: 1px solid rgba(64, 64, 64, 0.6);
          padding: 0 0 16px;
          margin-bottom: 16px;
          align-items: center;
          justify-content: space-between;

          .user {
            display: flex;
            align-items: center;

            &_ini {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              background-color: rgba(217, 217, 217, 0.1);
              padding: 2px;
              box-sizing: border-box;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 12px;
              font-size: 16px;
              font-family: $saira-700;
              letter-spacing: 1.5px;
            }

            &_name {
              font-family: $saira-500;
              color: $white_80;
              font-size: 14px;
            }
          }

          .total {
            display: flex;

            &_amount {
              margin-right: 6px;
              font-family: $saira-700;

            }

            &_symbol {
              font-family: $saira-700;
            }
          }
        }
      }

      &:before {
        position: absolute;
        content: '';
        top: -3px;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        border-top-left-radius: 32px;
        border-top-right-radius: 32px;
        background: linear-gradient(88deg, #4D9636 5.04%, #AAABA9 32.65%, rgba(246, 252, 90, 0.80) 94.51%);
      }

      .close_friends {
        position: absolute;
        right: 22px;
        top: 22px;
        display: block;
        width: 20px;
        height: auto;
      }
    }
  }
}

.invite_button {
  position: fixed;
  bottom: 80px;
  left: 0;
  padding: 0 16px 0;
  right: 0;

  &_button {
    width: 100%;
    background: #4d9636;
    margin: 0;
    padding: 15px 45px;
    font-size: 16px;
    text-align: center;
    transition: 0.3s ease-in-out;
    background-size: 200% auto;
    border: none;
    color: white;
    border-radius: 10px;
    background-position: right center;
    display: block;
    font-family: $saira-600;
    line-height: 1;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;

      &:hover {
        background: #316620;
        color: #fff;
        text-decoration: none;
      }
    }

    &.no_bg {
      border: 1px solid rgba(64, 64, 64, 0.6);
      background: rgba(255, 255, 255, 0.04);
      backdrop-filter: blur(2px);
    }

    &:hover {
      background: #316620;
      color: #fff;
      text-decoration: none;
      cursor: pointer;
    }

    @media (min-width: 768px) {
      font-size: 24px;
      min-width: 196px;
    }
  }
}

.button_loader {
  width: 13px;
  height: 13px;
  border: 2px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.has_error {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &_svg {
    transform: rotateY(180deg);

    &.rotate {
      animation: rotation_svg 1s linear infinite;
    }
  }

  p {
    margin: 0 0 10px;
    text-align: center;
    font-family: $saira-500;
    font-size: 18px;
  }

  svg {
    width: 40px;
  }

  @keyframes rotation_svg {
    0% {
      transform: rotate(0deg) rotateY(180deg);
    }
    100% {
      transform: rotate(360deg) rotateY(180deg);
    }
  }


  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.email_acc {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;

  label {
    margin: 0 0 6px;
    color: #fff;
    font-size: 14px;
    font-family: $saira-500;
  }

  .input_email {
    border-radius: 10px;
    padding: 14px 12px;
    background-color: transparent;
    border: 1px solid #2f2f2f;
    color: #fff;

    &:is(:-webkit-autofill, :autofill) {
      background-color: transparent !important;
    }


    &.invalid {
      border-color: #D72727;
    }

    &.valid {
      border-color: rgb(77, 150, 54);
    }
  }

  .email_acc_error {
    small {
      color: #D72727;
    }
  }
}

.no_account_popup_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.5);

  .no_account_popup {
    padding: 24px 16px;
    border: 1px solid #4D9636;
    border-radius: 10px;
    background: #070f04;
    display: flex;
    flex-direction: column;

    h2 {
      font-family: $saira-500;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      margin: 0 0 20px;
    }
    p {
      font-family: $saira-400;
      color: $white_80;
      text-align: center;
      margin: 0 0 20px;
    }

    .popup_cta {
      width: 100%;
      display: flex;

      button {
        flex: 1;
        border-radius: 8px;
        background: #D72727;
        box-shadow: 0 25px 50px -12px rgba(255, 246, 34, .3);
        padding: 8px 32px;
        box-sizing: border-box;
        color: #fff;
        font-family: $saira-400;
        font-size: 12px;
        font-style: normal;
        line-height: 16px;
        border: 1px solid transparent;
        white-space: nowrap;

        &:first-child {
          margin-right: 10px;
          background: #4d9636;
        }
      }

    }
  }
}

.is_account {
  display: flex;
  flex-direction: column;
  align-items: center;
  &_email {
    font-size: 26px;
    font-family: $saira-600;
    margin: 16px 0 24px;
    color: #4D9636;
  }
  &_descr {
    font-size: 16px;
    text-align: center;
    font-family: $saira-400;
    color: $white-80;
    margin: 10px 0;
  }
}

.buy_description {
  color: $white_50;
  font-size: 9px;
  text-align: center;

}

.stat_item {
  width: 100%;
  display: flex;
  margin-bottom: 2px;
  justify-content: space-between;
  font-size: 12px;

  &_title {
    color: $white_50;
    font-family: $saira-400;
  }

  &_value {
    font-family: $saira-700;
  }
}

.sign_up {
  height: 100%;
  overflow: auto;
  padding: 0 10px 10px 0;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    width: 3px;               /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: transparent;        /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(77, 150, 54);    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 1px solid #000;  /* creates padding around scroll thumb */
  }
  .input-group {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0 16px;
    .special-label {
      display: none;
    }
    label, .special-label {
      font-size: 12px;
      font-family: $saira-600;
      margin: 0 0 2px;
    }
    .d-block {
      position: absolute;
      bottom: -8px;
      width: 100%;
    }

    small {
      font-family: $saira-400;
      font-size: 12px;
      color: #D72727;
    }
    .showPassIcon {
      position: absolute;
      width: 20px;
      z-index: 4;
      top: 50%;
      right: 12px;
      cursor: pointer
    }
  }
  .form-control {
    border-radius: 10px;
    padding: 10px 12px;
    background-color: transparent;
    border: 1px solid #2f2f2f;
    color: #fff;
    width: 100%;
    box-sizing: border-box;

    &:is(:-webkit-autofill, :autofill) {
      background-color: transparent !important;
    }


    &.is-invalid {
      border-color: #D72727;
    }

    &.valid {
      border-color: rgb(77, 150, 54);
    }
  }
}
