.frame {
  height: calc(100%);

  &_header {
    height: 40px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    font-family: $saira-400;
    line-height: 1;
  }

  &_content {
    //box-sizing: border-box;
    margin: 0 -16px;
    background: #070f04;
    min-height: calc(100% - 78px);
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    padding: 16px 16px 0;
    @media all and (max-height: 670px) {
      //height: auto;
    }

    &:after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -2;
      //background: linear-gradient(88deg, #4D9636 5.04%, #AAABA9 32.65%, rgba(246, 252, 90, 0.80) 94.51%);
      background: linear-gradient(179deg, rgba(77, 150, 54, 0.60) 0.44%, rgba(246, 252, 90, 0.60) 12.34%);

      filter: blur(44.349998474121094px);
    }

    &:before {
      position: absolute;
      content: '';
      top: -3px;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      border-top-left-radius: 32px;
      border-top-right-radius: 32px;
      background: linear-gradient(88deg, #4D9636 5.04%, #AAABA9 32.65%, rgba(246, 252, 90, 0.80) 94.51%);
    }
  }
}

