.fight_arena_animation {
    height: 120px;
    max-width: 310px;
    margin: 0 auto;
    position: relative;

    &.rotate {
        transform: scale(-1, 1)
    }
}

.anim_wrapper {
    position: absolute;
    animation-delay: 0.5s;
    transform: translate(0,0);
}

.anim_wrapper_l {
    &.p_1_attack.play {
        animation: move_r_attack 2s linear forwards;
    }

    &.p_1_def.play {
        animation: move_r_def 1.5s linear forwards;
    }
    &.p_1_death.play {
        animation: move_r_death 1.5s linear forwards;
    }

    left: 0;
}

.anim_wrapper_r {
    &.p_2_attack.play {
        animation: move_l_attack 2s linear forwards;
    }

    &.p_2_def.play {
        animation: move_l_def 2s linear forwards;
    }
    &.p_2_death.play {
        animation: move_l_death 2s linear forwards;
    }

    right: 0;

}

@keyframes move_r_attack {
    100% {
        /*left: 90px;*/
        transform: translate(90px,0);
        -webkit-transform: translate(90px,0)
    }
}

@keyframes move_r_def {
    100% {
        /*left: 90px;*/
        transform: translate(90px,0);
        -webkit-transform: translate(90px,0)
    }
}

@keyframes move_r_death {
    100% {
        /*left: 90px;*/
        transform: translate(90px,0);
        -webkit-transform: translate(90px,0)
    }
}

@keyframes move_l_attack {
    100% {
        /*right: 90px;*/
        transform: translate(-90px,0);
        -webkit-transform: translate(-90px,0)
    }
}

@keyframes move_l_def {
    100% {
        /*right: 90px;*/
        transform: translate(-90px,0);
        -webkit-transform: translate(-90px,0)
    }
}
@keyframes move_l_death {
    100% {
        /*right: 90px;*/
        transform: translate(-90px,0);
        -webkit-transform: translate(-90px,0)
    }
}