@font-face {
  font-family: 'Saira-300';
  src: url("../public/fonts/Saira/static/Saira-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  font-stretch: normal;
}

@font-face {
  font-family: 'Saira-400';
  src: url("../public/fonts/Saira/static/Saira-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  font-stretch: normal;
}

@font-face {
  font-family: 'Saira-500';
  src: url("../public/fonts/Saira/static/Saira-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  font-stretch: normal;
}

@font-face {
  font-family: 'Saira-600';
  src: url("../public/fonts/Saira/static/Saira-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  font-stretch: normal;
}

@font-face {
  font-family: 'Saira-700';
  src: url("../public/fonts/Saira/static/Saira-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  font-stretch: normal;
}