@import "penalty_shot";
@import "flip_card";
@import "combo_game";
@import "clash";
@import "how_to_play";
@import "pvp";


.pte_page {

  &_title {
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    font-family: $saira-600;
    margin: 0 0 30px;
    max-width: 270px;
  }

  .frame_content {
    padding-top: 22px;
  }

  .games_list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    background: #070f04;

    .game_item {
      box-sizing: border-box;
      width: 100%;
      margin-bottom: 16px;
      overflow: hidden;
      height: 126px;
      position: relative;
      padding-bottom: 16px;
      border-bottom: 1px solid rgba(64, 64, 64, 0.6);

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }

      &.no_available {
        &:after {
          content: 'Comming Soon...';
          position: absolute;
          font-family: $saira-500;
          top: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $white_50;
          left: 0;
          height: 100%;
          width: 100%;
          background: rgba(0, 0, 0, 0.8);
          z-index: 3;
        }
      }

      &.locked {
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          z-index: 3;
        }
      }

      &_wrapper {
        position: relative;
        display: flex;
        height: 100%;
        color: #fff;
        text-decoration: none;
      }

      &_poster {
        width: 40%;
        border-radius: 6px;
        overflow: hidden;
        border: 1px solid rgba(64, 64, 64, 0.6);
        margin-right: 20px;
        position: relative;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          top: 0;
          z-index: 0;

        }

        .timer {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          background: rgba(0, 0, 0, 0.5);

          &_wrapper {
            border: 1px solid rgba(64, 64, 64, 0.6);
            background: #0d0d1a;
            backdrop-filter: blur(2px);
            padding: 6px 6px;
            font-size: 12px;
            box-sizing: border-box;
            border-radius: 6px;
          }
        }
      }

      &_description {
        width: 60%;

        .title_wrapper {
          margin: 0 0 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .game_lock {
            display: flex;

            img {
              width: 14px;
              margin-right: 4px;
            }

            p {
              margin: 0;
              font-family: $saira-700;
              font-size: 12px;
            }
          }
        }

        &_title {
          font-size: 20px;
          margin: 0;
          line-height: 22px;
          box-sizing: border-box;
          position: relative;
          z-index: 1;
          font-family: $saira-500;
        }

        &_text {
          margin: 0;
          color: $white_50;
          font-size: 14px;
          box-sizing: border-box;
          line-height: 16px;
          width: 100%;
          font-family: $saira-400;
        }
      }


    }
  }
}