@import "../styles/index";

body {
  font-family: Roboto, sans-serif;
  padding: 0;
  margin: 0;
  background-color: #000;
}

.app {
  min-height: 100vh;
  max-width: 430px;
  margin: 0 auto;

  &_wrapper {
    height: 100vh;
  }

  .pages {
   //height: calc(100% - 79px);
    position: relative;
    overflow: scroll;
    color: #fff;
    padding: 16px 16px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}