.flip_card {
  position: relative;

  .loader_wrapper {
    border-radius: 0;
  }

  .frame_content {
    padding-top: 22px;
    min-height: calc(100% - 78px);
    height: auto;
  }

  &_title {
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    font-family: $saira-600;
    margin: 0 0 12px;
  }

  &_description {
    font-size: 16px;
    text-align: center;
    color: $white_50;
    margin: 0 0 16px;
    line-height: 18px;
    font-family: $saira-300;
  }
  .flip_card_content {
    //position: relative;
  }

  .boost_data {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 12px;
    .moves {
      display: flex;
      justify-content: flex-start;
      &_title {
        font-size: 14px;
        color: $white_80;
        margin: 0 4px 0 0;
        font-family: $saira-300;
      }
      &_value {
        font-size: 14px;
        color: #fff;
        margin: 0 6px 0 0;
        font-family: $saira-700;
        width: 20px;
      }
    }
    .multiplier {
      flex: 1;
      display: flex;
      justify-content: center;
      &_title {
        font-size: 14px;
        color: $white_80;
        margin: 0 6px 0 0;
        font-family: $saira-300;
      }
      &_value {
        font-size: 14px;
        color: #fff;
        margin: 0 4px 0 0;
        font-family: $saira-700;
        width: 20px;
        &.tick {
           animation: tick 0.5s linear 1;
        }
      }
    }
    .reward {
      display: flex;
      justify-content: flex-end;
      flex-wrap: nowrap;
      &_title {
        font-size: 14px;
        color: $white_80;
        margin: 0 4px 0 0;
        font-family: $saira-300;
      }
      &_value {
        white-space: nowrap;
        font-size: 14px;
        color: #4d9636;
        margin: 0 6px 0 0;
        font-family: $saira-700;
        &.tick {
           animation: tick 0.5s linear 1;
        }
      }
    }
  }

  .cards_wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    position: relative;
    &.splash {
      &:after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        animation: splash 1s linear;
      }
    }

  }

  .card {
    position: relative;
    z-index: 2;
    width: 100%;
    height: auto;
    min-height: 100px;
    display: grid;
    align-items: center;
    justify-content: center;
    transition: all linear 0.3s;
    transform: rotate3d(0, 1, 0, 180deg);
    position: relative;
    overflow: hidden;
    border: 1px solid rgba(64, 64, 64, 0.6);
    //background: #151A60;
    box-sizing: border-box;
    border-radius: 10px;
    background: radial-gradient(83.8% 49.98% at 50% 50.02%, #447E32 0%, #1D3715 57%, #070F04 100%);

    img {
      width: 40px;
      height: auto;
      opacity: 0;
    }
  }

  .card:hover {
    cursor: pointer;
    transform: rotate3d(0, 1, 0, 180deg);
  }

  .card.flipped {
    transform: unset;
    transform-style: preserve-3d;
    .backSide {
      svg {
        transition: none;
        opacity: 0;
      }
    }
    img {
      transition: opacity 0.3s linear;
      opacity: 1;
    }
  }

  .backSide {
    //background: #151A60;
    background: radial-gradient(83.8% 49.98% at 50% 50.02%, #447E32 0%, #1D3715 57%, #070F04 100%);
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      transition: opacity .3s ease-in-out;
      transform: rotateY(180deg);;
    }
  }

  .flipped .backSide {
    transform: rotate3d(0, 1, 0, 180deg);
  }
}

@keyframes tick{
  0%  {transform: scale(1);}
  25%  {transform: scale(0.7);}
  50%  {transform: scale(1);}
  75%  {transform: scale(1.3);}
  100%  {transform: scale(1);}
}

@keyframes splash{
  0%  {background: rgba(192, 16, 16, 0.4);}

  50%  {background: rgba(192, 16, 16, 0.2);}

  100%  {background: rgba(192, 16, 16, 0);}
}