.pvp_game {


  .invite_button_button {
    padding: 10px 45px;
    margin-bottom: 4px;
    &.lobby_btn {
      padding: 14px 45px;
    }

    &:disabled {
      color: $white_50;
      background: #316620;
      cursor: not-allowed;

      &:hover {
        background: #316620;
        color: $white_50;
        text-decoration: none;
      }
    }
  }
  .frame_content {
    box-sizing: border-box;
    padding-top: 16px;
    height: calc(100% - 56px);
  }

  .arena_bg {
    position: relative;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    padding: 8px 16px;
    box-sizing: border-box;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &:after {
      border-top-left-radius: 32px;
      border-top-right-radius: 32px;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      -webkit-box-shadow: inset 0px 0px 47px 0px rgba(242,26,19,1);
      -moz-box-shadow: inset 0px 0px 47px 0px rgba(242,26,19,1);
      box-shadow: inset 0px 0px 47px 0px rgba(242,26,19,1);
    }

    &.damage {
      &:after {
        animation: damage 2s linear;
      }
    }

    @keyframes damage {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

  }

  .lobby_game {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &_logo {
      width: 100%;
      margin: 0 auto 40px;
      max-width: 220px;
    }

    .points_amount {
      width: 100%;

      &_title {
        font-family: $saira-500;
        font-size: 28px;
        margin: 0 0 20px;
        text-align: center;
      }

      &_list {
        padding: 0;
        margin-bottom: 30px;

      }

      &_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        border: 1px solid rgba(255, 255, 255, 0.1);
        padding: 10px 16px;
        margin-bottom: 14px;
        border-radius: 4px;
        font-size: 14px;
        position: relative;

        &:after {
          display: none;
          content: 'Ready to Fight';
          font-size: 10px;
          font-family: $saira-400;
          color: $white_80;
          padding: 2px 4px;
          box-sizing: border-box;
          border-radius: 3px;
          border: 1px solid rgba(77, 150, 54);
          background: #0c0f06;
          position: absolute;
          line-height: 1;
          right: 8px;
          top: -8px;
          animation: pulse 2s infinite;
        }

        &.has_player {
          &:after {
            display: block;
          }
        }

        @-webkit-keyframes pulse {
          0% {
            -webkit-box-shadow: 0 0 0 0 rgba(77, 150, 54, 0.8);
          }
          70% {
            -webkit-box-shadow: 0 0 0 10px rgba(77, 150, 54, 0);
          }
          100% {
            -webkit-box-shadow: 0 0 0 0 rgba(77, 150, 54, 0);
          }
        }
        @keyframes pulse {
          0% {
            -moz-box-shadow: 0 0 0 0 rgba(77, 150, 54, 0.8);
            box-shadow: 0 0 0 0 rgba(77, 150, 54, 0.4);
          }
          70% {
            -moz-box-shadow: 0 0 0 10px rgba(77, 150, 54, 0);
            box-shadow: 0 0 0 10px rgba(77, 150, 54, 0);
          }
          100% {
            -moz-box-shadow: 0 0 0 0 rgba(77, 150, 54, 0);
            box-shadow: 0 0 0 0 rgba(77, 150, 54, 0);
          }
        }

        &:last-child {
          margin-bottom: 0;
        }


        &_title {
          font-family: $saira-700;
        }

        &_amount {
          font-family: $saira-700;

          .mp {
            color: #4D9636;
            margin-left: 4px;
          }
        }

        &.active {
          border-color: #4D9636;
        }
      }
    }
  }
}

.info_components {
  &.full_height {
    position: absolute;
    background: #070f04;
    z-index: 10;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
  }
  height: calc(100% - 16px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-family: $saira-400;
    color: $white_50;
    text-align: center;
  }
}

.fighting-game {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  height: 100%;
  width: 100%;
  flex-direction: column;

  .waiting_screen {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-size: 16px;
      color: $white_50;
      font-family: $saira-500;
    }
  }


  .game-title {
    text-align: center;
    margin-bottom: 20px;
    color: #e94560;
    font-size: 24px;
  }

  .game-content {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .top_info {
      .message {
        position: absolute;
        width: 100%;
        top: 40%;
        left: 0;
        text-align: center;
        font-family: $saira-500;
        color: #f0a500;
        font-size: 14px;
      }
    }

    .top_board {
      position: relative;
      margin-bottom: 12px;

      img {
        width: 100%;
        height: auto;
        display: block;
      }

      .score {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: $saira-700;
        //font-size: 24px;
        line-height: 1;

        &_round {
          width: 62px;
          display: flex;
          justify-content: center;
          padding: 6px 0 0 4px;
          align-items: center;
          font-size: 11px;
          color: $white_80;
          font-family: $saira-500;
          @media all and (max-width: 400px) {
            padding: 5px 0 0 4px;
          }

        }

        &_points {
          color: #fff;
          display: flex;
          flex: 1;
          box-sizing: border-box;
          justify-content: space-between;
          padding: 0 20px;
          width: 100%;

          .point {

            display: flex;
            justify-content: center;
            align-items: center;
            width: 25px;
            box-sizing: border-box;
            padding-top: 4px;

            &.less {
              color: #F6465D;
            }

            &.more {
              color: #2EBD85;
            }

            &.eq {
              color: #fff;
            }
          }
        }

        .score_players {
          flex: 1;
          display: flex;

          .player {
            font-size: 12px;
            width: 50%;
            display: flex;
            box-sizing: border-box;
            align-items: center;
            padding-top: 4px;

            @media all and (max-width: 400px) {
              font-size: 10px;
            }

            &:first-child {
              padding-right: 44px;
              justify-content: flex-end;
              @media all and (max-width: 400px) {
                padding-right: 38px;
              }
            }

            &:last-child {
              justify-content: flex-start;
              padding-left: 48px;
              @media all and (max-width: 400px) {
                padding-left: 43px;
              }
            }
          }
        }

        &_timer {
          font-size: 11px;
          line-height: 16px;
          padding: 0 0 4px 6px;
          color: #2EBD85;
          @media all and (max-width: 400px) {
            padding: 0 0 2px 6px;
          }

          &.les_10 {
            color: #f0a500;
          }

          &.les_5 {
            color: #e94560;
          }
        }
      }
    }

    .game_info {
      display: flex;
      font-family: $saira-500;
      color: $white_80;
      justify-content: center;

      .round_number {
        width: 100%;
        max-width: 120px;
        @media all and (min-height: 700px) {
          max-width: 200px;
        }
      }
    }

    .timer {
      width: 100%;
      height: 8px;
      border-radius: 4px;
      box-sizing: border-box;
      border: 1px solid rgba(255, 255, 255, 0.1);
      margin-bottom: 20px;
      @media all and (min-height: 700px) {
        margin-bottom: 40px;
      }

      span {
        display: block;
        height: 100%;
        background: linear-gradient(90deg, #F6465D -5.73%, #F87C30 38.68%, #2EBD85 100%);

      }
    }

    .round-results_content {
      display: flex;
      flex-direction: column;
      height: 100%;

      .results_view {
        margin: auto 0;

        .results_text {
          font-family: $saira-500;
          text-align: center;
        }

        &_point {
          display: flex;
          height: 1px;
          justify-content: center;

          &.anim {
            span {
              animation: point linear 1.5s forwards;

              animation-delay: 2s;
            }
          }

          span {
            font-size: 24px;
            color: #17c65b;
            position: absolute;
            font-family: $saira-700;
            opacity: 0;
            transform: translateY(-20px);
          }

          @keyframes point {
            0% {
              transform: translateY(0);
              opacity: 0;
            }
            50% {
              transform: translateY(-50px);
              opacity: 1;
            }
            100% {
              transform: translateY(-100px);
              opacity: 0;
            }

          }
        }

        &_attack, &_defense {
          display: flex;
          justify-content: space-between;
          font-family: $saira-400;
          color: $white_80;

          .dir {
            text-transform: uppercase;
            width: 90px;
            display: flex;
            justify-content: center;
            box-sizing: border-box;
            border-radius: 4px;
            //border: 1px solid #4D9636;
            border: 1px solid rgba(255, 255, 255, 0.1);
            color: #ffffff;
            cursor: pointer;
            font-family: $saira-400;
            padding: 6px 20px;
            font-size: 12px;

            &.up {
              border-color: #4D9636;
            }

            &.down {
              border-color: #e94560;
            }

            &.neutral {
              background-color: #2a2a3a;
            }
          }
        }
      }


      h2 {
        margin: 0 0 40px;
        text-align: center;
        font-family: $saira-500;
      }

      p {
        margin-bottom: 20px;
        font-size: 18px;
      }
    }

    .player-turn {
      margin: auto;
      width: 100%;

      h2 {
        font-family: $saira-500;
        text-align: center;
        margin: 8px 0 12px;
        font-size: 16px;
        color: #fff;
        @media all and (min-height: 700px) {
          font-size: 22px;
          max-width: 300px;
          margin: 0 auto 20px;
        }
      }

      &_titles {
        display: flex;
        justify-content: space-between;

        p {
          width: 50%;
          text-align: center;
          font-family: $saira-500;
          margin: 0;
        }

        .title_attc {
          color: #F6465D;
        }

        .title_def {
          color: $white_50;
        }
      }

      .directions {
        display: flex;
        justify-content: center;
        font-family: $saira-500;
        margin: 0 -16px 32px;
        @media all and (min-height: 700px) {
          margin: 0 0 50px;
          font-size: 22px;
        }

        .action-group {
          flex: 1;
          padding: 16px;
          box-sizing: border-box;

          &:nth-child(1) {
            border-right: 1px solid $white_50;
          }

          &_wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .action-group_buttons {
              display: flex;
              flex-direction: column;

              .btn {
                border: 1px solid rgba(255, 255, 255, 0.1);
                background: transparent;
                border-radius: 4px;
                color: #ffffff;
                cursor: pointer;
                font-family: $saira-400;
                padding: 6px 20px;
                font-size: 12px;
                transition: all 0.3s, transform 0.1s;

                &:hover:not(:disabled) {
                  border-color: #4D9636;
                  transform: translateY(-2px);
                }

                &:disabled {
                  background-color: #2a2a3abf;
                  color: #808080;
                  cursor: not-allowed;
                  transform: none;
                }

                &.selected {
                  border-color: #4D9636;
                  color: #ffffff;
                }
              }

              button {
                margin: 0 0 6px;
              }
            }

            .action_img {
              width: 100%;
              max-width: 80px;
            }
          }
        }

        font-size: 18px;
      }

      .actions {
        display: flex;
        margin: 0 -16px;


        .action_buttons {
          display: flex;
          flex-direction: column;
        }

        .action-group {
          flex: 1;
          padding: 16px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: space-between;


          &.attc {
            border-right: 1px solid $white_50;

            img {
              margin-right: 10px;
            }

            .btn {
              position: relative;

              &.selected {
                border-color: #4D9636;
                color: #ffffff;
              }
            }
          }

          &.def {
            img {
              margin-left: 10px;
            }

            .btn {
              &.selected {
                background-color: $white_50;
                color: #ffffff;
              }
            }
          }

          img {
            display: block;
            width: 64px;
            height: auto;
            @media all and (min-height: 700px) {
              width: 80px;
            }
          }
        }
      }
    }

    .fight_screen {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .round_number {
        width: 100%;
        height: auto;
        max-width: 300px;
        @media all and (min-height: 700px) {
          margin-bottom: 60px;
        }
      }

      .fight_img {
        width: 100%;
        height: auto;
        max-width: 130px;
        margin: 0 auto;
      }

      .fight_arena {
        width: 100%;
        margin: auto 0;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 4px;
        padding: 16px;
        box-sizing: border-box;
      }
    }

    .scores {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      font-size: 18px;
      color: #f0a500;
    }
  }
}