.combo_game {
  .frame_content {
    padding-top: 22px;
    height: calc(100% - 78px);
  }

  .combo_card_title {
    font-size: 20px;
    font-family: $saira-500;
    margin: 0 0 12px;
    text-align: center;
  }

  .game_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: scroll;
    position: relative;

    @media all and (min-height: 780px){
      justify-content: center;
    }
  }

  .combo_scheme {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    position: relative;
    margin-bottom: 40px;

    .combo_scheme_card {

      &_wrapper {
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        min-height: 100px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border: 1px solid rgba(255, 255, 255, 0.1);
        box-sizing: border-box;
        box-shadow: 0px 1px 10px -6px rgb(60, 239, 163);

        &.selected {
          border: 1px solid #fff;
        }

        &.result_0 {
          border: 1px solid #D72727;
          position: relative;
          &:after {
            color: #D72727;
            content: '0%';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            font-family: $saira-500;
            bottom: -30px;
            font-size: 18px;
          }
        }

        &.result_1 {
          border: 1px solid #fece82;
          position: relative;
          &:after {
            color: #fece82;
            content: '10%';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            font-family: $saira-500;
            bottom: -30px;
            font-size: 18px;
          }
        }

        &.result_2 {
          border: 1px solid rgb(23, 198, 91);
          position: relative;
          &:after {
            color: rgb(23, 198, 91);
            content: '25%';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            font-family: $saira-500;
            bottom: -30px;
            font-size: 18px;
          }
        }

        .svg_img {
          max-width: 25px;
        }

        img {
          display: block;
          width: 100%;
          max-width: 40px;
          height: auto;
        }

      }

    }
  }

  .combo_cards {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    position: relative;
    margin-bottom: 40px;

    .combo_card {


      &_wrapper {
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        min-height: 100px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border: 1px solid rgba(255, 255, 255, 0.1);
        box-sizing: border-box;
        box-shadow: 0px 1px 10px -6px rgb(60, 239, 163);

        &.selected {
          opacity: 0.5;
          cursor: not-allowed;
          box-shadow: none;
        }

        img {
          display: block;
          width: 100%;
          max-width: 40px;
          height: auto;
        }
      }

    }

  }

  .invite_button_button {
    display: flex;
    justify-content: center;
    align-items: center;

    .button_loader {
      margin-right: 6px;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
}