.clash_game {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .top_board {
    position: relative;

    img {
      width: 100%;
      height: auto;
      display: block;
    }

    .score {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      height: 100%;
      display: flex;
      font-family: $saira-700;
      font-size: 16px;
      line-height: 1;
      padding: 0 18px;
      box-sizing: border-box;
      justify-content: space-between;

      .points {
        color: #fff;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        padding-top: 2px;
        width: 40px;
        justify-content: center;

        &.less {
          color: #F6465D;
        }

        &.more {
          color: #2EBD85;
        }

        &.eq {
          color: #fff;
        }


        //&:first-child {
        //  color: #12f336;
        //}
        //&:last-child {
        //  color: #12f336;
        //}
      }
    }
  }

  .frame_content {
    padding-top: 22px;
    height: calc(100% - 78px);
  }


  .card-game {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //background-color: #070f04;
    color: #ffffff;
    width: 100%;
    height: 100%;
    overflow: hidden;


    &_title {
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      font-family: $saira-600;
      margin: 0 0 12px;
    }

    .game-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.3rem;
      width: 100%;
    }

    .board-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      width: 100%;

      .next_card_wrapper {
        position: absolute;
        right: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;

        height: 100%;

        p {
          font-size: 10px;
          margin: 0 0 10px;
        }

        .next_card {

          width: 60px;
          height: 70px;
          box-shadow: 0px 0px 6px 2px rgba(60, 239, 163, 0.1);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 0.25rem;
          overflow: hidden;

          img {
            width: 80%;
            height: auto;
          }
        }
      }

      &.ai {
        .next_card_wrapper {
          padding-top: 74px;
          @media all and (min-height: 700px) {
            padding-top: 119px;
          }
        }

        .cell {
          &.filled {
            border: 1px solid rgba(255, 255, 255, 0.1);
            box-sizing: border-box;
            box-shadow: 0px 1px 10px -6px rgb(60, 239, 163);
            color: #fff;
          }
        }
      }

      &.user {
        .next_card_wrapper {
          padding-top: 102px;
          @media all and (min-height: 700px) {
            padding-top: 147px;
          }
        }

        .cell {
          &.filled {
            border: 1px solid rgba(255, 255, 255, 0.1);
            box-sizing: border-box;
            box-shadow: 0px 1px 10px -6px rgb(60, 239, 163);
            color: #fff;
          }
        }
      }
    }

    .board {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 0.5rem;
      position: relative;

      &.inactive {
        //opacity: 0.5;
      }
    }

    .column-markers {
      position: absolute;
      bottom: -1.2rem;
      left: 0;
      right: 0;
      display: flex;
      justify-content: space-around;
    }

    .column-marker {
      width: 0;
      height: 0;
      border-left: 0.3rem solid transparent;
      border-right: 0.3rem solid transparent;
      border-bottom: 0.3rem solid rgba(255, 255, 255, 0.78); // Изменено с border-top на border-bottom
      opacity: 0;
      transition: opacity 0.3s ease, transform 0.3s ease;

      &.visible {
        opacity: 1;
        animation: bounceUp 0.5s infinite alternate;
      }
    }

    @keyframes bounceUp {
      from {
        transform: translateY(0);
      }
      to {
        transform: translateY(-0.25rem);
      }
    }

    .cell {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5rem;
      font-weight: bold;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 0.25rem;
      position: relative;

      @media all and (min-height: 700px) {
        width: 65px;
        height: 65px;
      }

      img {
        width: 80%;
        height: auto;
      }


      &.empty {
        background-color: #326620;

        &:hover {
          background-color: #326620;
        }
      }

      &.available {
        animation: glow 1.5s infinite alternate;
      }

      &.burning {
        animation: burn 0.5s forwards;
      }

      @keyframes glow {
        from {
          background-color: #326620;
        }
        to {
          background-color: #58ad3d;
        }
      }

      .multiplier {
        position: absolute;
        top: -4px;
        right: -4px;
        font-size: 12px;
        color: #d212f3;
      }
    }

    .column-score {

      text-align: center;
      font-family: $saira-600;
      line-height: 1;
      color: #fff;
      font-size: 12px;

      &:nth-child(4) {
        left: 0;
      }

      &:nth-child(5) {
        left: 4.5rem;
      }

      &:nth-child(6) {
        left: 9rem;
      }
    }

    .status, .current-card {
      font-size: 1.25rem;
      margin-top: 1rem;
      text-align: center;
      color: #e0e0e0;
    }

    .timer {
      width: 100%;
      height: 8px;
      border-radius: 4px;
      box-sizing: border-box;
      border: 1px solid rgba(255, 255, 255, 0.1);

      span {
        display: block;
        height: 100%;
        background: linear-gradient(90deg, #F6465D -5.73%, #F87C30 38.68%, #2EBD85 100%);

      }
    }
  }

  .play_btn_wrapper {
    position: absolute;
    padding: 16px;
    width: 100%;
    box-sizing: border-box;

    .invite_button_button {
      animation: glow 1.5s infinite alternate;
      font-size: 18px;
      padding: 13px 45px;
    }
  }

  @keyframes burn {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.5;
      background-color: #e74c3c;
    }
    100% {
      transform: scale(0);
      opacity: 0;
    }
  }

}